import { Component, Show } from 'solid-js'
import styles from '~/components/text-style-editor/text-style-editor.module.scss'
import TextStyleFontSelect from '~/components/text-style-font-select/text-style-font-select'
import TextStyleFontVariantSelect from '~/components/text-style-font-variant-select/text-style-font-variant-select'
import TextStyleToggles from '~/components/text-style-toggles/text-style-toggles'
import TextStyleFontSize from '~/components/text-style-font-size/text-style-font-size'
import TextStyleLineHeight from '~/components/text-style-line-height/text-style-line-height'
import TextStyleLetterSpacing from '~/components/text-style-letter-spacing/text-style-letter-spacing'
import { textStyles, updateStyles } from '~/services/current-document/text-styles'
import TextStyleColorFill from '~/components/text-style-color-fill/text-style-color-fill'
import TextStylesStrokeCollection from '~/components/text-styles-stroke-collection/text-styles-stroke-collection'
import TextStylesShadowsCollection from '~/components/text-styles-shadows-collection/text-styles-shadows-collection'
import { chapter } from '~/services/current-document/chapter'
import TextStylePicker from '~/components/text-style-picker/text-style-picker'
import { TextStyleEditorProps } from '~/components/text-style-editor/text-style-editor.interfaces'

const TextStyleEditor: Component<TextStyleEditorProps> = ({ document }) => {
  const seriesId = () => chapter()?.comicId

  const onFieldFocus = () => {
    document.hasFocus = false
  }

  const onFieldBlur = () => {
    document.hasFocus = true
  }

  return (
    <div class={styles.editor}>
      <Show when={seriesId()}>
        {seriesId => (
          <TextStylePicker
            seriesId={seriesId()}
            document={document}
          />
        )}
      </Show>

      <div class={styles.fontSettings}>
        <TextStyleFontSelect
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleFontVariantSelect
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>

      <div class={styles.fields}>
        <TextStyleFontSize
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleToggles
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleLineHeight
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleLetterSpacing
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>

      <div class={styles.fields}>
        <TextStyleColorFill
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStylesStrokeCollection
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStylesShadowsCollection
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>
    </div>
  )
}

export default TextStyleEditor

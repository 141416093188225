import { Component } from 'solid-js'
import { v4 as uuidv4 } from 'uuid'
import { TypesetEntryProps } from '~/components/typeset-entry/typeset-entry.interfaces'
import BubbleIcon from '~/assets/icons/message-alt.svg'
import ArrowIcon from '~/assets/icons/arrow-left.svg'
import OnomatopoeiaIcon from '~/assets/icons/onomatopoeia.svg'
import styles from '~/components/typeset-entry/typeset-entry.module.scss'
import { createTypesetText } from '~/services/current-document/typeset-texts'
import TypesetText from '~/editor/typeset-text/typeset-text'
import { textStyles } from '~/services/current-document/text-styles'
import { highlightTranslation } from '~/services/current-document/translations'
import Comments from '~/components/comments/comments'
import { translations } from '~/translations'
import { getBoundingBox } from '~/services/current-document/loading/typeset-texts'

const TypesetEntry: Component<TypesetEntryProps> = ({ index, entry, document }) => {
  let ref!: HTMLDivElement
  const typesettingDocumentPage = () => document.pages[entry.page.index]

  const insertTypesetText = (event: MouseEvent) => {
    event.stopPropagation()

    scrollIntoFocus()

    const typesetText = new TypesetText({
      id: uuidv4(),
      text: entry.translatedText ?? translations().editor.typesetting.exampleText,
      boundingBox: getBoundingBox(entry),
      styles: textStyles(),
      page: typesettingDocumentPage(),
      date: new Date(entry.date ?? '').toUTCString(),
      settings: document.settings,
      translationId: null
    })

    createTypesetText(typesetText)
  }

  const scrollIntoFocus = () => {
    if(entry.startPoint){
      highlightTranslation(entry, {
        scrollView: 'canvas',
      })
    }else{
      document.scrollTo(typesettingDocumentPage().getPageTop())
    }
  }

  const cssClasses = {
    [styles.TypesetEntry]: true,
    [styles.isOnomatopoeia]: entry.isOnomatopoeia
  }

  return (
    <div>
      <div
        classList={cssClasses}
        onClick={scrollIntoFocus}
        ref={ref}
      >
        <aside>
          <div class={styles.index}>
            {index + 1}
          </div>
          <button class={styles.insert} onClick={insertTypesetText}>
            <ArrowIcon class={styles.add} />
            <BubbleIcon class={styles.bubble} />
            <span>{translations().general.actions.insert}</span>
          </button>
        </aside>
        <div class={styles.text}>
          {entry.translatedText}
        </div>
        {entry.isOnomatopoeia && (
          <OnomatopoeiaIcon class={styles.icon} />
        )}
      </div>
      <Comments entry={entry} />
    </div>
  )
}

export default TypesetEntry

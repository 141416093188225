import { Component, createEffect } from 'solid-js'
import Button from '~/components/button/button'
import { ReplaceTextStyleFormType, ReplaceTextStyleSchema, TextStyleReplaceModalProps } from '~/components/text-style-replace-modal/text-style-replace-modal.interfaces'
import styles from '~/components/text-style-replace-modal/text-style-replace-modal.module.scss'
import ConfirmIcon from '~/assets/icons/check.svg'
import TextField from '~/components/fields/text/text'
import { SubmitHandler, createForm, setValue, valiForm } from '@modular-forms/solid'
import Modal from '~/components/modal/modal'
import { translations } from '~/translations'
import ToggleField from '~/components/fields/toggle-field/toggle-field'

const TextStyleReplaceModal: Component<TextStyleReplaceModalProps> = ({ open, setOpen, onConfirm, newStyle }) => {
  const onClose = () => {
    setOpen(false)
  }

  const [form, { Form, Field }] = createForm<ReplaceTextStyleFormType>({
    initialValues: {
      replaceAll: true
    },
    validate: valiForm(ReplaceTextStyleSchema)
  })

  createEffect(() => {
    setValue(form, 'path', newStyle.path ?? translations().editor.typesetting.textStyle.form.create.defaultValue)
  })

  const onSubmit: SubmitHandler<ReplaceTextStyleFormType> = (values) => {
    onConfirm(values)
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().editor.typesetting.textStyle.form.replace.title}
    >
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name='path'>
          {(field, props) => (
            <TextField
              label={translations().editor.typesetting.textStyle.form.create.name}
              type='text'
              {...field}
              {...props}
            />
          )}
        </Field>

        <Field name='replaceAll' type='boolean'>
          {(field, props) => (
            <ToggleField
              label={translations().editor.typesetting.textStyle.form.replace.replaceAll}
              {...field}
              {...props}
            />
          )}
        </Field>

        <div class={styles.buttons}>
          <Button onClick={onClose}>
            {translations().general.actions.cancel}
          </Button>
          <Button
            style='lagoon'
            type='submit'
            icon={ConfirmIcon}
          >
            {translations().general.actions.confirm}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default TextStyleReplaceModal
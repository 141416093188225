import CanvasView from '~/components/canvas-view/canvas-view'
import styles from '~/components/typesetting-view/typesetting-view.module.scss'
import { Component, Show, createEffect, createSignal } from 'solid-js'
import { TypesettingViewProps } from '~/components/typesetting-view/typesetting-view.interfaces'
import { chapter, getChapterData, setChapter } from '~/services/current-document/chapter'
import { getTranslations } from '~/services/database/translations'
import { useNavigate } from '@solidjs/router'
import { createInitialTypesetTexts } from '~/services/current-document/loading/initial-typeset-texts'
import { getTypesetTexts } from '~/services/database/typeset-texts'
import { loadTypesetTexts } from '~/services/current-document/loading/typeset-texts'
import { loadTranslations } from '~/services/current-document/loading/translations'
import { DocumentProps } from '~/editor/document/document.interfaces'
import Document from '~/editor/document/document'
import { getComments } from '~/services/database/comments'
import SidebarHeader from '~/components/sidebar-header/sidebar-header'
import ImportTranslation from '~/components/import-translation/import-translation'
import SmallButton from '~/components/small-button/small-button'
import TypesetList from '~/components/typeset-list/typeset-list'
import ExportModal from '~/components/export-modal/export-modal'
import ImportIcon from '~/assets/icons/download.svg'
import ExportIcon from '~/assets/icons/export.svg'
import TextStyleEditor from '~/components/text-style-editor/text-style-editor'
import ValidateChapterStep from '~/components/validate-chapter-step/validate-chapter-step'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const TypesettingView: Component<TypesettingViewProps> = ({ chapterId }) => {
  const [showTranslationImport, setShowTranslationImport] = createSignal(false)
  const [isTranslationViewLoading, setTranslationViewLoading] = createSignal(true)
  const [isTypesettingViewLoading, setTypesettingViewLoading] = createSignal(true)
  const [showExportModal, setExportModal] = createSignal(false)
  const [translationDocument, setTranslationDocument] = createSignal<Document>()
  const [typesettingDocument, setTypesettingDocument] = createSignal<Document>()
  const [pages, setPages] = createSignal<Document['pages']>([])
  const navigate = useNavigate()

  // Scroll Sync
  createEffect(() => {
    const doc1 = typesettingDocument()
    const doc2 = translationDocument()
    if(doc1 && doc2){
      doc1.linkedDocument = doc2
      doc2.linkedDocument = doc1
    }
  })

  const onTypesettingDocumentReady = async (props: DocumentProps) => {
    const typesettingDocument = new Document(props)
    setTypesettingDocument(typesettingDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const typesetTextsData = await getTypesetTexts(chapterId)

    const cleanPagesExist = chapterData.pages.clean.length > 0
    const typesetTextsExist = typesetTextsData.length > 0
    if (!cleanPagesExist) {
      navigate(`/series/chapter/${chapterId}/upload`, { replace: true })
    } else {
      if (typesetTextsExist) {
        loadTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          typesetTextsData,
          setIsLoading: setTypesettingViewLoading
        })
      } else {
        createInitialTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          translationsData,
          setIsLoading: setTypesettingViewLoading
        })
      }
    }

    return typesettingDocument
  }

  const onTranslationDocumentReady = async (props: DocumentProps) => {
    const translationDocument = new Document(props)
    translationDocument.addEventListener('pagesready', () => {
      setPages(translationDocument.pages)
    })
    setTranslationDocument(translationDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const commentsData = await getComments(chapterId)

    loadTranslations({
      currentDocument: translationDocument,
      chapterData,
      translationsData,
      commentsData,
      setIsLoading: setTranslationViewLoading
    })

    return translationDocument
  }

  const onImportTranslations = () => {
    setShowTranslationImport(true)
  }

  const onExportTypesetting = async () => {
    setExportModal(true)
  }

  createEffect(() => {
    const isLoading = isTranslationViewLoading() || isTypesettingViewLoading()
    setLoadingState({
      loading: isLoading
    })
  })
  
  return (
    <div class={styles.typesettingView}>
      <ImportTranslation
        open={showTranslationImport()}
        setOpen={setShowTranslationImport}
        chapterId={chapterId}
        pages={pages()}
      />
      <CanvasView
        settings={{
          mode: 'translation',
          readOnly: true
        }}
        onReady={onTranslationDocumentReady}
      />
      <CanvasView
        settings={{
          mode: 'typesetting',
          hideControls: true
        }}
        onReady={onTypesettingDocumentReady}
      />
      <div class={styles.sideView}>
        <Show when={chapter()}>
          {chapter => (
            <SidebarHeader
              chapter={chapter()}
              mode='typesetting'
            />
          )}
        </Show>
        <ExportModal
          open={showExportModal()}
          setOpen={setExportModal}
          chapterId={chapterId}
        />
        <Show when={typesettingDocument()}>
          {doc => (
            <>
              <div class={styles.toolbar}>
                <TextStyleEditor
                  document={doc()}
                />
              </div>
              <TypesetList
                document={doc()}
              />
            </>
          )}
        </Show>
        <ValidateChapterStep
          mode='typesetting'
          aside={(
            <>
              <SmallButton
                icon={ImportIcon}
                onClick={onImportTranslations}
              >
                {translations().editor.io.importTranslations}
              </SmallButton>
              <SmallButton
                icon={ExportIcon}
                onClick={onExportTypesetting}
              >
                {translations().editor.io.exportTypesetting}
              </SmallButton>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default TypesettingView
import { boolean, InferOutput, nonEmpty, object, pipe, string } from 'valibot'
import { TextStyles } from '~/types/editor/text-styles'

export interface TextStyleReplaceModalProps{
  open: boolean
  setOpen: (value: boolean) => boolean
  onConfirm: (values: ReplaceTextStyleFormType) => void
  newStyle: TextStyles
}

export const ReplaceTextStyleSchema = object({
  path: pipe(string(), nonEmpty()),
  replaceAll: boolean()
})

export type ReplaceTextStyleFormType = InferOutput<typeof ReplaceTextStyleSchema>
import { Component, For, Show, createEffect, createResource, createSignal, onCleanup, onMount } from 'solid-js'
import styles from '~/components/text-style-picker/text-style-picker.module.scss'
import Chevron from '~/assets/icons/chevron-down.svg'
import TextStyleEntry from '~/components/text-style-entry/text-style-entry'
import { selectedTypesetTexts, typesetTexts } from '~/services/current-document/typeset-texts'
import { textStyles, updateStyles } from '~/services/current-document/text-styles'
import { TextStyles } from '~/types/editor/text-styles'
import { TextStylePickerProps } from '~/components/text-style-picker/text-style-picker.interfaces'
import { deleteTextStyle, getTextStyles, insertTextStyle, updateTextStyle } from '~/services/database/text-styles'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { translations } from '~/translations'
import EmptyIcon from '~/assets/icons/document-remove.svg'
import EmptyState from '~/components/empty-state/empty-state'
import TextStyleSaveModal from '~/components/text-style-save-modal/text-style-save-modal'
import SmallButton from '~/components/small-button/small-button'
import { chapter } from '~/services/current-document/chapter'
import TextStyleReplaceModal from '~/components/text-style-replace-modal/text-style-replace-modal'
import { ReplaceTextStyleFormType } from '~/components/text-style-replace-modal/text-style-replace-modal.interfaces'

const TextStylePicker: Component<TextStylePickerProps> = ({ seriesId, document }) => {
  const [textStylesCollection, { refetch: refetchStyles }] = createResource(seriesId, getTextStyles)

  createEffect(() => {
    refetchStyles()
  })

  // Apply Shortcuts
  const onKeyDownHandler = (event: KeyboardEvent) => {
    const chordKey = event.altKey
    const styles = textStylesCollection.latest
    if (styles && styles[0] && event.code === 'Digit1' && chordKey && !event.shiftKey) {
      updateStyles(styles[0])
    }
    if (styles && styles[1] && event.code === 'Digit2' && chordKey && !event.shiftKey) {
      updateStyles(styles[1])
    }
    if (styles && styles[2] && event.code === 'Digit3' && chordKey && !event.shiftKey) {
      updateStyles(styles[2])
    }
    if (styles && styles[3] && event.code === 'Digit4' && chordKey && !event.shiftKey) {
      updateStyles(styles[3])
    }
    if (styles && styles[4] && event.code === 'Digit5' && chordKey && !event.shiftKey) {
      updateStyles(styles[4])
    }
    if (styles && styles[5] && event.code === 'Digit6' && chordKey && !event.shiftKey) {
      updateStyles(styles[5])
    }
    if (styles && styles[6] && event.code === 'Digit7' && chordKey && !event.shiftKey) {
      updateStyles(styles[6])
    }
    if (styles && styles[7] && event.code === 'Digit8' && chordKey && !event.shiftKey) {
      updateStyles(styles[7])
    }
    if (styles && styles[8] && event.code === 'Digit9' && chordKey && !event.shiftKey) {
      updateStyles(styles[8])
    }
    if (styles && styles[9] && event.code === 'Digit0' && chordKey && !event.shiftKey) {
      updateStyles(styles[9])
    }
  }

  onMount(() => {
    window.addEventListener('keydown', onKeyDownHandler)
  })

  onCleanup(() => {
    window.removeEventListener('keydown', onKeyDownHandler)
  })

  // Current Style Display
  const originalTextStyle = () => (textStylesCollection.latest ?? []).find(textStyle => textStyle.path === textStyles().path)
  // Only returns true if the current style path 
  // - exists in the original styles and 
  // - the current style is different from the original style
  // - compare both styles without metadata and transforms
  const isStyleModified = () => {
    let isStyleModified = false
    const currentStyles = textStyles()
    const originalStyles = originalTextStyle()

    if (originalStyles) {
      const originalTextStyleSimplified = {
        path: originalStyles.path,
        props: originalStyles.props,
        strokes: originalStyles.strokes,
        shadows: originalStyles.shadows,
      }
      const currentStylesSimplified = {
        path: currentStyles.path,
        props: currentStyles.props,
        strokes: currentStyles.strokes,
        shadows: currentStyles.shadows,
      }
      isStyleModified = JSON.stringify(originalTextStyleSimplified) !== JSON.stringify(currentStylesSimplified)
    }

    return isStyleModified
  }

  const currentStyle = () => {
    const selectedStyle = textStyles()
    return (
      <span>
        {selectedStyle.path}
        {isStyleModified() ? ` (${translations().editor.typesetting.textStyle.notSaved})` : ''}
      </span>
    )
  }

  // Dropdown Logic
  const [toggled, setToggled] = createSignal(false)

  const onStyleClick = (textStyle: TextStyles) => () => {
    const selectionExists = Boolean(selectedTypesetTexts().length > 0)
    updateStyles(textStyle, {
      updateDB: selectionExists,
      updateSelected: selectionExists
    })
    setToggled(false)
  }

  const onEscapeHandler = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      onBlurHandler()
    }
  }

  const onBlurHandler = () => {
    window.removeEventListener('click', onBlurHandler)
    window.removeEventListener('keydown', onEscapeHandler)
    onBlur()
  }

  const onBlur = () => {
    setToggled(false)
  }

  const onToggle = () => {
    setToggled(value => !value)
    if (toggled()) {
      setTimeout(() => {
        window.addEventListener('click', onBlurHandler)
        window.addEventListener('keydown', onEscapeHandler)
      })
    }
  }

  const optionsClasses = () => ({
    [styles.options]: true,
    [styles.toggled]: toggled()
  })

  // Save Logic
  const [saveConfirmation, setSaveConfirmation] = createSignal(false)

  const openStyleSaveModal = () => {
    document.hasFocus = false
    setSaveConfirmation(true)
  }

  const onStyleSave = async (path: string) => {
    const seriesId = chapter()?.comicId
    const styles = textStyles()
    styles.path = path
    const selectionExists = Boolean(selectedTypesetTexts().length > 0)

    if (seriesId) {
      await insertTextStyle(styles, path, seriesId)
      updateStyles(styles, {
        updateDB: selectionExists,
        updateSelected: selectionExists
      })
      refetchStyles()
    }

    document.hasFocus = true
  }

  // Replace Logic
  const [replaceConfirmation, setReplaceConfirmation] = createSignal(false)

  const openStyleReplaceModal = () => {
    document.hasFocus = false
    setReplaceConfirmation(true)
  }

  const onStyleReplace = async ({ path, replaceAll }: ReplaceTextStyleFormType) => {
    const textStyleId = originalTextStyle()?.meta?.id
    const textStylePath = originalTextStyle()?.path
    const styles = textStyles()
    styles.path = path
    const selectionExists = Boolean(selectedTypesetTexts().length > 0)

    if (textStyleId && textStylePath) {
      // Update DB entry
      await updateTextStyle(textStyleId, styles, path)
      // Update sideview styles and selected text styles
      updateStyles(styles, {
        updateDB: selectionExists,
        updateSelected: selectionExists
      })
      // Update existing typeset texts with the changes
      if(replaceAll){
        typesetTexts().forEach(async (typesetText) => {
          if (typesetText.styles.path === textStylePath){
            const stylesWithOriginalTransforms: TextStyles = {
              ...styles,
              transforms: typesetText.styles.transforms
            }
            typesetText.applyStyles(stylesWithOriginalTransforms, { updateDB: true })
          }
        })
      }
      refetchStyles()
    }

    document.hasFocus = true
  }

  // Delete Logic
  const [textStylesToDelete, setTextStylesToDelete] = createSignal<TextStyles>()
  const [deleteConfirmation, setDeleteConfirmation] = createSignal(false)

  const onStyleDeleteConfirm = (textStyle: TextStyles) => () => {
    setTextStylesToDelete(textStyle)
    setDeleteConfirmation(true)
  }

  const onStyleDelete = async () => {
    const id = textStylesToDelete()?.meta?.id
    if (id) {
      await deleteTextStyle(id)
      refetchStyles()
    }
    setDeleteConfirmation(false)
  }

  return (
    <>
      {/* Save New Confirmation */}
      <TextStyleSaveModal
        open={saveConfirmation()}
        setOpen={setSaveConfirmation}
        onConfirm={onStyleSave}
      />

      {/* Delete Confirmation */}
      <ConfirmationModal
        open={deleteConfirmation()}
        setOpen={setDeleteConfirmation}
        title={translations().editor.typesetting.textStyle.deleteConfirmation.title}
        description={textStylesToDelete()?.path ?? ''}
        onConfirm={onStyleDelete}
        type='danger'
      />

      {/* Replace Confirmation */}
      <TextStyleReplaceModal
        open={replaceConfirmation()}
        setOpen={setReplaceConfirmation}
        newStyle={textStyles()}
        onConfirm={onStyleReplace}
      />

      <div class={styles.currentStyle}>
        <div class={styles.textStylePicker}>
          <button
            class={styles.select}
            onClick={onToggle}
          >
            {currentStyle()}
            <Chevron class={styles.icon} />
          </button>

          <div classList={optionsClasses()}>
            <For each={textStylesCollection.latest} fallback={(
              <EmptyState
                icon={EmptyIcon}
                label={translations().editor.typesetting.textStyle.emptyState.title}
              />
            )}>
              {(textStyle, index) => (
                <TextStyleEntry
                  onClick={onStyleClick(textStyle)}
                  onDelete={onStyleDeleteConfirm(textStyle)}
                  current={false}
                  textStyle={textStyle}
                  index={index()}
                />
              )}
            </For>
          </div>
        </div>
        
        <Show when={isStyleModified() && originalTextStyle()}>
          <SmallButton
            onClick={openStyleReplaceModal}
            borderless
          >
            {translations().general.actions.replace}
          </SmallButton>
        </Show>
        
        <SmallButton
          onClick={openStyleSaveModal}
          borderless
        >
          {translations().general.actions.save}
        </SmallButton>
      </div>
    </>
  )
}

export default TextStylePicker

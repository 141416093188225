import { useParams } from '@solidjs/router'
import SubscriptionGate from '~/components/subscription-gate/subscription-gate'
import TypesettingView from '~/components/typesetting-view/typesetting-view'
import { TypesettingParams } from '~/types/routes/series/typesetting'

export default function Typesetting() {
  const { id } = useParams<TypesettingParams>()
  return (
    <SubscriptionGate requiredRole='typesetting'>
      <TypesettingView chapterId={id} />
    </SubscriptionGate>
  )
}
